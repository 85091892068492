
.status-tag{  


    p {
       
        font-weight: bold;
        border-radius:9999px;
        font-size: 12px;
        width: max-content;
        padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: #CA9D00
    }

}