.pac-container {
    z-index: 9999!important;

}

.pac-logo::after{
        
    display: none!important;
 
    
}